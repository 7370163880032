import { DataGridPro } from "@mui/x-data-grid-pro";
import { Delete } from "@mui/icons-material";
import { Card, CardContent, CardHeader, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { API } from "../../lib/api";
const OpportunitiesTable = ({ opportunities, setOpportunities, setFeedback, user }) => {

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleRemoveOpp = async (id) => {
        if (!user) {
            setFeedback({open: true, message: "No User Detected...", type: "error"})
            return
        } 
        const response = await API.Dashboard.RemoveOpp(user,id)
        if (response.status === 200) {
            setOpportunities(prev => prev.filter(opp => opp._id !== id)); // 👈 Update state
            setFeedback({open: true, message: "Removed Opportunity From Dashboard!", type: "success"})
        } else {
            setFeedback({open: true, message: "Error removing opportunity...", type: "error"})
        }
    }

    const OppResponsiveColumns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: ({row: { _id, name } }) => <a href={`/opportunities/${_id}`}>{name}</a>
        },
        // Conditionally show/hide columns
        ...(isMobile ? [] : [
            {
                field: "projectNumber",
                headerName: "Project #",
                flex: 1,
            },
            {
                field: "quoteNumber",
                headerName: "Quote #",
                flex: 1,
            },
            {
                field: "customer",
                headerName: "Customer",
                flex: 1,
                renderCell: ({row: { customer} }) => customer?.organizationName,
                valueGetter: (params) => params?.organizationName 
            },
            {
                field: "primaryContact",
                headerName: "Primary Contact",
                flex: 1,
                renderCell: ({row: { primaryContact} }) => primaryContact?.name,
                valueGetter: (params) => params?.name 
            },
            {
                field: "owner",
                headerName: "Owner",
                flex: 1,
                renderCell: ({ row: { owner } }) => owner ? `${owner.firstName} ${owner.lastName}` : 'Unassigned',
                valueGetter: (params) => `${params.firstName} ${params.lastName}` 
            },
            {
                field: "amount",
                headerName: "Amount",
                flex: 1,
                renderCell: ({ row: { amount }}) => amount ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                }).format(amount) :'$0.00'
            },
            {
                field: "closeDate",
                headerName: "Close Date",
                renderCell: ({ row: { closeDate } }) => closeDate ? closeDate.split("T")[0] : "",
                flex: 1
            }
        ]),
        {
            field: "salesStage",
            headerName: "Stage",
            flex: 1,
            renderCell: ({ row: { salesStage } }) => {
                return (
                    <label className={`badge  ${salesStage === '01 - Lead' ? 'bg-light-primary' : salesStage === '08 - Won' ? 'bg-light-success' :salesStage === '05 - Committee Approval' ? 'bg-light-warning' : salesStage === '04 - Internal Revision' ? 'bg-light-danger' : salesStage === '06 - Quote Sent' ? 'bg-light-primary' : salesStage === '02 - Identified' ? 'bg-light-dark' : salesStage === '03 - Committee' ? 'bg-light-secondary' : salesStage === "07 - External Revision" ? 'bg-light-danger' : 'bg-light-info'}`} style={{minHeight: '75%', minWidth: '75%', padding: "0", lineHeight: '3', fontSize: '14px'}}>{salesStage}</label>
                );
            },
        },
        {
            field: "_id",
            headerName: "",
            flex: 1,
            renderCell: ({ row: { _id }}) => {
                const id = _id 
                return (
                    <Tooltip title="Remove From Dashboard">
                        <IconButton onClick={() => handleRemoveOpp(id)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                )
                
            },
        }
    ]

    return (
        <div className="col-xl-12 col-md-12" style={{marginTop: "1em", marginBottom: "1em"}}>
            <Card>
                <CardHeader title="Opportunities" sx={{backgroundColor: "#eaf6f2", color: "#2ca87f"}} />
                <CardContent sx={{backgroundColor: "#eaf6f2", color: "#2ca87f"}}>
                    <DataGridPro
                        sx={{backgroundColor: "white"}}
                        checkboxSelection
                        rows={opportunities ? opportunities : []}
                        columns={OppResponsiveColumns}
                        getRowId={(row) => row._id}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                    />
                </CardContent>
            </Card>
        </div>
    )
}

export default OpportunitiesTable