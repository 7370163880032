import { useMediaQuery } from '@mui/material';

const ResponsiveColumns = () => {
    // Define the breakpoints
    const isMobile = useMediaQuery('(max-width:600px)');

    return [
        {
            field: "organizationName",
            headerName: "Organization",
            flex: 1,
            cellClassName: "name-column--cell",
          },
            // Conditionally show/hide columns
            ...(isMobile ? [] : [
                {
                    field: "name",
                    headerName: "Name",
                    flex: 1
                },
                {
                    field: "primaryEmail",
                    headerName: "Email",
                    flex: 1
                },
                {
                    field: "phoneNumber",
                    headerName: "Phone #",
                    flex: 1
                },
                {
                    field: "status",
                    headerName: "Oracle Status",
                    flex: 1
                },
            ]),
    ];
};

export default ResponsiveColumns