// ActionButtons.js
import React from 'react';
import { IconButton, Switch, Tooltip } from "@mui/material";

const ActionButtons = ({ buttons }) => (
    <div className="row">
        <div className="col">
            {buttons.map(({ title, onClick, icon: Icon, color, toggle, showArchived }, idx) => (
                <Tooltip key={idx} title={title}>
                    {
                        toggle ? (
                            <Switch
                                checked={showArchived} // ✅ bind state here
                                onChange={onClick}
                        />
                        ) : (

                    <IconButton onClick={onClick}>
                        <Icon color={color || "primary"} />
                    </IconButton>
                        )
                    }
                </Tooltip>
            ))}
        </div>
    </div>
);

export default ActionButtons;
