import { useEffect, useState } from "react"
import { API } from "../../lib/api";
import { useAuth } from "../../contexts/AuthContext";
import Feedback from "../Feedback/Feedback";
import OpportunitiesTable from "./OpportunitiesTable";
import CustomersTable from "./CustomersTable";

const Content = () => {
    const [userDashboard, setUserDashboard] = useState({})
    const [opportunities, setOpportunities] = useState([])
    const [customers, setCustomers] = useState([])
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""}) 
    const { user } = useAuth();

    useEffect(() => {
        async function getData() {
            const userDashboardResponse = await API.Dashboard.FetchByUser(user?.id || user?.userId)
            if (userDashboard) {
                console.log(userDashboardResponse)
                setUserDashboard(userDashboardResponse)
                setOpportunities(userDashboardResponse?.opportunities)
                setCustomers(userDashboardResponse?.customers)
            }
        }
        getData()
    }, [])
    return (
        <>
            <Feedback props={feedback} setFeedback={setFeedback} />
            <div className="row">

                {/* Opportunities Table */}
                <OpportunitiesTable opportunities={opportunities} setOpportunities={setOpportunities} setFeedback={setFeedback} user={localStorage.id} />
                <CustomersTable customers={customers} setCustomers={setCustomers} setFeedback={setFeedback} user={localStorage.id} />
            </div>
        </>
    )    
}

export default Content