import { DriveFileMove } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import ReactECharts from 'echarts-for-react';

const Bar = ({ xData, seriesData, title, isModule}) => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        const data = params[0];
        return `${data.name}<br/>Total Opportunities: ${data.value}`;
      }
    },
    xAxis: {
      type: 'category',
      data: xData,
      axisLabel: {
        // rotate: 45, // or 30, depending on your space
        interval: 0, // force show all labels
        overflow: 'break', // or 'truncate', 'breakAll'
      }
    },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'bar'
          }
        ]
    }

    return (
        <div className="col-12 col-md-10 col-lg-10">
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">{title}</h5>
                </div>
                <ReactECharts
                    option={option}
                    style={{ height: '500px' }}
                    opts={{renderer: 'svg'}} // use svg to render the chart.
                />
                {isModule && 
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <Tooltip title={"Add Module To Dashboard - Currently Unavailable"}>
                        <IconButton>
                            <DriveFileMove color={"secondary"} />
                        </IconButton>
                    </Tooltip>
                  </div>
                }
            </div>
        </div>
      </div>
    )
}

export default Bar