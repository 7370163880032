import { useState } from "react"
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";
import { API } from "../../lib/api";

const AuthForm = ({ setMessage }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [inputFocused, setInputFocused] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();
    const [formData,setFormData] = useState({ email: "", password: "" })

    const inputStyle = {
        fontSize: '16px',
        ...(isMobile && inputFocused && {
          transform: 'scale(1)', // Prevent zooming when focused
          transition: 'transform 0.2s ease-in-out',
        }),
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await API.Authentication.Login(formData)

        if (response.status === 200) {
            const userData = response.data;
            if (userData && userData.id) {
                login(userData);
                userData.role === "engineer" ? setTimeout(() => navigate("/egr-dashboard"), 0) : setTimeout(() => navigate("/dashboard"), 0)
            } else {
                setMessage({open: true, message: "Incorrect Username or Password...", type: "error"})
            }
        } else {
            setMessage({open: true, message: "Incorrect Username or Password...", type: "error"})
        }

        e.target.reset();
    }

    return (
        <div className="auth-main">
            <div className="auth-wrapper v1">
                <form className="auth-form" onSubmit={handleSubmit}>
                    <div className="card my-5">

                        <div className="card-body">
                            <div className="text-center" style={{display: "flex", justifyContent: "center"}}>
                                <a href="/"><img src="images/LOGO.png" alt="img" style={{width: "60px", marginBottom: "1em"}}/></a>
                            </div>
                            <h4 className="text-center f-w-500 mb-3" style={{textAlign: "center", marginBottom: "1em"}}>Login with your email</h4>
                            <div className="form-group mb-3">
                                <input type="email" className="form-control" id="floatingInput" placeholder="Email Address"
                                    style={inputStyle}
                                    onFocus={() => setInputFocused(true)}
                                    onBlur={() => setInputFocused(false)}
                                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <input type="password" className="form-control" id="floatingInput1" placeholder="Password" 
                                    style={inputStyle}
                                    onFocus={() => setInputFocused(true)}
                                    onBlur={() => setInputFocused(false)}
                                    onChange={(e) => setFormData({...formData, password: e.target.value})}
                                />
                            </div>
                            <div className="d-grid mt-4">
                                <Button variant="contained" type="submit" sx={{width: "100%"}}>Login</Button>
                            </div>
                            <div className="d-grid" style={{marginTop: "1em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px", textAlign: "center", margin: 0}}>Beta - v25.1.3</p>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default AuthForm