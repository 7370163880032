import { IconButton, Tooltip } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

const Module = ({title, icon, description, onClick}) => {
    return (
        <div className="col-8 col-md-6 col-lg-5 col-xl-3">
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0" style={{textAlign: "center"}}>{title}</h5>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    {icon}
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Tooltip title={description}>
                        <IconButton onClick={onClick ? onClick : null}>
                            <OpenInNewIcon color={"secondary"} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Add Module To Dashboard - Currently Unavailable"}>
                        <IconButton>
                            <DriveFileMoveIcon color={"secondary"} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
        </div>        
    )
}

export default Module