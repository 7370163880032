import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import GroupIcon from '@mui/icons-material/Group';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
// import TopicIcon from '@mui/icons-material/Topic';
import ContactsIcon from '@mui/icons-material/Contacts';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

export const navItemsData = [
    { iconId: "custom-status-up", text: "Dashboard", link: '/dashboard', icon: <DashboardCustomizeIcon/> },
    { iconId: "opportunities", text: "Opportunities", link: '/opportunities', icon: <AccountTreeIcon/> },
    { icodId: "scheduling", text: "Scheduling", link: "/schedule", icon: <WorkHistoryIcon/> },
    { iconId: "reporting", text: "Reporting & Modules", link: "/reporting", icon: <AnalyticsIcon/> },
    { iconId: "customers", text: "Customers", link: "/customers", icon: <RecentActorsIcon/> },
    { iconId: "contacts", text: "Contacts", link: "/contacts", icon: <ContactsIcon/> },
    { iconId: "users", text: "Users", link: "/users", icon: <GroupIcon/> },
    // { iconId: "user-guides", text: "User Guides", link: '/constr', icon: <TopicIcon/> },
];

export const navItemsDataEngineering = [
    { iconId: "custom-status-up", text: "Dashboard", link: '/dashboard', icon: <DashboardCustomizeIcon/> },
    { iconId: "quotes", text: "Quotes", link: '/quotes', icon: <AccountTreeIcon/> },
    { iconId: "users", text: "Users", link: "/users", icon: <GroupIcon/> },
];

// -- REMOVE ON COMPLETION -- //
export const tempTasks = [
    { _id: 1, project: {_id: 1, name: "QT10"}, name: "TK0001", assignedTo: {firstName: "Keifer", lastName: "Rasner"}, startDate: "2024-6-10T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-6-9" },
    { _id: 2, project: {_id: 2, name: "QT20"}, name: "TK0002", assignedTo: {firstName: "Alyssa", lastName: "Harmon"}, startDate: "2024-7-1T00", updatedOn: "2024-6-15", status: "In Progress", priority: "Medium", dueDate: "2024-6-10" },
    { _id: 3, project: {_id: 3, name: "QT30"}, name: "TK0003", assignedTo: {firstName: "Jordan", lastName: "Baker"}, startDate: "2024-8-5T00", updatedOn: "2024-7-20", status: "Completed", priority: "High", dueDate: "2024-6-11" },
    { _id: 4, project: {_id: 4, name: "QT40"}, name: "TK0004", assignedTo: {firstName: "Taylor", lastName: "Smith"}, startDate: "2024-5-10T00", updatedOn: "2024-5-30", status: "In Progress", priority: "Critical", dueDate: "2024-6-12" },
    { _id: 5, project: {_id: 5, name: "QT50"}, name: "TK0005", assignedTo: {firstName: "Chris", lastName: "Johnson"}, startDate: "2024-9-1T00", updatedOn: "2024-8-25", status: "Not Started", priority: "Low", dueDate: "2024-6-13" },
    { _id: 6, project: {_id: 1, name: "QT10"}, name: "TK0006", assignedTo: {firstName: "Keifer", lastName: "Rasner"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "In Progress", priority: "Medium", dueDate: "2024-8-12" },
    { _id: 7, project: {_id: 1, name: "QT10"}, name: "TK0007", assignedTo: {firstName: "Keifer", lastName: "Rasner"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-12" },
    { _id: 8, project: {_id: 1, name: "QT10"}, name: "TK0008", assignedTo: {firstName: "Keifer", lastName: "Rasner"}, startDate: "2024-8-15T00", updatedOn: "2024-6-1", status: "In Progress", priority: "Medium", dueDate: "2024-8-15" },

    { _id: 9, project: {_id: 1, name: "QT11"}, name: "TK0009", assignedTo: {firstName: "Evan", lastName: "Mercer"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Completed", priority: "Critical", dueDate: "2024-8-19" },
    { _id: 10, project: {_id: 1, name: "QT11"}, name: "TK0010", assignedTo: {firstName: "Evan", lastName: "Mercer"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Completed", priority: "Critical", dueDate: "2024-8-19" },
    { _id: 11, project: {_id: 1, name: "QT11"}, name: "TK0011", assignedTo: {firstName: "Sophie", lastName: "Caldwell"}, startDate: "2024-8-15T00", updatedOn: "2024-6-1", status: "Not Started", priority: "High", dueDate: "2024-8-19" },
    { _id: 12, project: {_id: 1, name: "QT12"}, name: "TK0012", assignedTo: {firstName: "Owen", lastName: "Harrington"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-20" },
    { _id: 13, project: {_id: 1, name: "QT12"}, name: "TK0013", assignedTo: {firstName: "Owen", lastName: "Harrington"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Blocked", priority: "High", dueDate: "2024-8-20" },
    { _id: 14, project: {_id: 1, name: "QT23"}, name: "TK0014", assignedTo: {firstName: "Logan", lastName: "Whitmore"}, startDate: "2024-8-15T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Medium", dueDate: "2024-8-20" },
    { _id: 15, project: {_id: 1, name: "QT23"}, name: "TK0015", assignedTo: {firstName: "Logan", lastName: "Whitmore"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Blocked", priority: "Low", dueDate: "2024-8-21" },
    { _id: 16, project: {_id: 1, name: "QT27"}, name: "TK0016", assignedTo: {firstName: "Logan", lastName: "Whitmore"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-21" },
    { _id: 17, project: {_id: 1, name: "QT17"}, name: "TK0017", assignedTo: {firstName: "Miles", lastName: "Thornton"}, startDate: "2024-8-15T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-21" },
    { _id: 18, project: {_id: 1, name: "QT33"}, name: "TK0018", assignedTo: {firstName: "Miles", lastName: "Thornton"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-22" },
    { _id: 19, project: {_id: 1, name: "QT41"}, name: "TK0019", assignedTo: {firstName: "Miles", lastName: "Thornton"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-22" },
    { _id: 20, project: {_id: 1, name: "QT41"}, name: "TK0020", assignedTo: {firstName: "Sophie", lastName: "Caldwell"}, startDate: "2024-8-15T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-25" },
    { _id: 21, project: {_id: 1, name: "QT41"}, name: "TK0021", assignedTo: {firstName: "Chloe", lastName: "Beckett"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-26" },
    { _id: 22, project: {_id: 1, name: "QT66"}, name: "TK0022", assignedTo: {firstName: "Chloe", lastName: "Beckett"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-27" },
    { _id: 23, project: {_id: 1, name: "QT54"}, name: "TK0023", assignedTo: {firstName: "Chloe", lastName: "Beckett"}, startDate: "2024-8-15T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-30" },
    { _id: 24, project: {_id: 1, name: "QT53"}, name: "TK0024", assignedTo: {firstName: "Chloe", lastName: "Beckett"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-30" },
    { _id: 25, project: {_id: 1, name: "QT54"}, name: "TK0025", assignedTo: {firstName: "Chloe", lastName: "Beckett"}, startDate: "2024-8-12T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-30" },
    { _id: 26, project: {_id: 1, name: "QT54"}, name: "TK0026", assignedTo: {firstName: "Chloe", lastName: "Beckett"}, startDate: "2024-8-15T00", updatedOn: "2024-6-1", status: "Not Started", priority: "Low", dueDate: "2024-8-30" },
]