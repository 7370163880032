import { navItemsDataEngineering } from "../../../lib/config";
import NavItem from "./NavItem"
import NavUser from "./NavUser"

const SideMenu = () => {
    return (
        <nav className="pc-sidebar">

            <div className="navbar-wrapper">

                <div className="m-header">
                    <a href="#" className="b-brand text-primary">
                        <img src="/images/LOGO.png" alt="logo" style={{width: "60px", margin: "0.75em"}} />
                        <span className="badge bg-light-success rounded-pill ms-2 theme-version">v25.1.3</span>
                    </a>
                </div>

                <div className="navbar-content">

                    <NavUser />

                    <ul className="pc-navbar">
                        {navItemsDataEngineering.map((item, index) => (
                            <NavItem key={index} {...item} />
                        ))}
                    </ul>

                </div>

            </div>
        </nav>
    )
}

export default SideMenu