import { Switch, Tooltip } from "@mui/material";
import { useEffect, useState } from "react"
import { API } from "../../lib/api";

const CompletionStatus = ({project, pid, setFeedback, setLogs}) => {
    const [allowInForecast, setAllowInForecast] = useState(false)
    const [total, setTotal] = useState("")

    const handleAllowInForecast = async (e) => {
        const bool = e.target.checked
        setAllowInForecast(bool)
        const response = await API.Opportunities.UpdateSingle(pid, {allowInForecast: bool})
        if (response.status === 200) {
            setFeedback({ open: true, message: response.data.message, type: "success" })
            const fetchNewLogs = await API.OpportunityLogs.FetchByOppId(pid)
            setLogs(fetchNewLogs)
        } else {
            setAllowInForecast(false)
            setFeedback({ open: true, message: response?.data?.message ?  response.data.message : `Failed to update ${project.name}`, type: "error"})
        }
    }

    useEffect(() => {
        // async function getLines() {
        //     const response = await API.Lines.FetchByOpportunity(pid, localStorage.token);
        //     if (response.length) {
        //         // Extract amounts
        //         let temp = response.map(line => line.amount);
                
        //         // Calculate the sum
        //         const sum = temp.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        
        //         // Format as currency
        //         const formattedSum = new Intl.NumberFormat("en-US", {
        //             style: "currency",
        //             currency: "USD",
        //         }).format(sum);
        
        //         setTotal(formattedSum)
        //     } else {
        //         console.log("No lines found.");
        //     }
        // }
        // getLines()
        let temp = project?.lines && project?.lines.map(line => line.amount)

        const sum = temp && temp.reduce((a,c) => a + c, 0)
        const formattedSum = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(sum);

        setTotal(formattedSum)
        project?.allowInForecast && setAllowInForecast(project?.allowInForecast)
    }, [project]);

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div className="col-12 col-md-8 col-lg-8 col-xl-6">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">Opportunity - <a href="#">{project && project.name}</a></h5>
                    </div>
                    <div className="card-body">
                            <div className="mb-4">
                                <p className="mb-2">Win Probability <span className="float-end">{project?.winProbability}%</span></p>
                                <div className={`progress ${project?.winProbability > 80 ? 'progress-success' : project?.winProbability > 65 ? 'progress-primary' : project?.winProbability > 51 ? 'progress-warning' : 'progress-danger'}`} style={{height: "8px"}}>
                                    <div className="progress-bar" style={{width: `${project?.winProbability}%`}}></div>
                                </div>
                            </div>
                            <div className="row" style={{margin: "1em"}}>
                                <a href="#" className="btn btn-link-secondary">
                                <span style={{borderRadius: "50%", backgroundColor: "#ffc107", display: "inline-block", padding: "0.25rem", marginRight: "0.5em"}}></span>
                                    <span className="text-truncate w-100">Close Date: {project?.closeDate ? project.closeDate.split("T")[0] : "No Close Date"}</span>
                                </a>
                            </div>
                            <div className="row" style={{margin: "1em"}}>
                            <Tooltip title="Sum of Opportunity Lines">
                                <a href="#" className="btn btn-link-secondary">
                                <span style={{borderRadius: "50%", backgroundColor: "#ffc107", display: "inline-block", padding: "0.25rem", marginRight: "0.5em"}}></span>
                                    <span className="text-truncate w-100">Amount: {total}</span>
                                </a>
                            </Tooltip>
                            </div>
                            <div style={{display:"flex", justifyContent: "space-evenly", alignItems: "center", margin: "1em"}}>
                                <p style={{color: "#6c757d", fontSize: "14px"}}>Allow in ABS Forecast</p>
                                {/* <Tooltip title="Currently Unavailable"> */}
                                <Switch
                                checked={allowInForecast}
                                onChange={handleAllowInForecast}
                                />
                            {/* </Tooltip> */}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletionStatus