import { useAuth } from "../../../contexts/AuthContext";
import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton } from "@mui/material";
const NavUser = () => {
    const { logout, user } = useAuth(); // Import the login function from your AuthContex
    const handleLogout = () => {
        logout()
    }
    return (
        <div className="card pc-user-card">
            <div className="card-body">
                <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{flexShrink: 0}}>
                        <div style={{backgroundColor: "rgba(104,192,255,1)", borderRadius: "50%", width: "45px", height: "45px"}}></div>
                        {/* <img src="../assets/images/user/avatar-1.jpg" alt="user" className="user-avtar wid-45" style={{borderRadius: "50%"}} /> */}
                    </div>
                    <div style={{flexGrow: 1, marginLeft: "1rem", marginRight: "0.5rem"}}>
                        <h6 className="mb-0" id="curr_user">{user && user.name}</h6>
                        <small id="user-role" style={{display: "block"}}>{user?.department || "Department"}</small>
                        <small id="user-role">{user && user.role}</small>
                    </div>
                    {/* <a className="btn btn-icon btn-link-secondary avtar" data-bs-toggle="collapse" href="#pc_sidebar_userlink"> */}
                        <IconButton>
                            <FilterListIcon/>
                        </IconButton>
                    {/* </a> */}
                </div>
                <div className="collapse pc-user-links" id="pc_sidebar_userlink" style={{display: "flex"}}>
                    <div className="pt-3">
                        <a href="/settings">
                            <i className="ti ti-settings"></i>
                            <span>Settings</span>
                        </a>
                        <a href="#" onClick={handleLogout}>
                            <i className="ti ti-power"></i>
                            <span>Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavUser