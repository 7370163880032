import { useEffect, useState } from "react";
import { FormProvider } from "../../contexts/CreateRecordFormContext";
import RecordModal from "../../components/RecordModal/RecordModal";
import Feedback from "../../components/Feedback/Feedback";
import { API } from "../../lib/api";
import ResponsiveColumns from "./ResponsiveColumns";
import { AddCircle, Construction } from "@mui/icons-material";
import ActionButtons from "../../components/ActionButtons/ActionButtons";
import DataTable from "../../components/DataTable/DataTable";
import CustomersConfig from "../../lib/config/customers";
// import { jwtDecode } from "jwt-decode";
import { Box, CircularProgress } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

const Content = () => {
    const [customers, setCustomers] = useState([]) 
    const [selectedRows, setSelectedRows] = useState([]);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""}) 
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true);
    
    // let user = jwtDecode(localStorage?.token);
    const { user } = useAuth
    
    const columns = ResponsiveColumns()

    const addCustomer = (newCustomer) => setCustomers(prevCustomers => [...prevCustomers, newCustomer]);

    // Keep track of selected rows
    const handleSelectionChange = (selection) => setSelectedRows(selection)

    const handleOpenCreateModal = () => setOpenCreateModal(true);
    const handleCloseCreateModal = () => setOpenCreateModal(false);
      
    const handleOpenUpdateModal = () => selectedRows.length ? setOpenUpdateModal(true) : setFeedback({open: true, message: "Select at least one record to update!", type: "info"})
    const handleCloseUpdateModal = () => setOpenUpdateModal(false);


    // API Request to Create a User
    const handleCreateSubmit = async (data) => {
        const response = await API.Customers.Create(localStorage.id, data)

        if (response.status === 200) {
            addCustomer(response.data.data)
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }
        handleCloseCreateModal()
    };

    // API Request to Update a User
    const handleUpdateSubmit = async (data) => {
        let temp = []
        let updatedFields = {}
        
        Object.keys(data).forEach(key => {
            if (data[key]) {
                if (typeof data[key] === "object" && data[key].id) {
                    updatedFields[key] = data[key].id; // ✅ Extract only the ID
                } else {
                    updatedFields[key] = data[key];
                }
            }
        });
        
        for (let i=0; i<selectedRows.length; i++) {
            temp.push({_id: selectedRows[i], data: updatedFields })
        }

        const response = await API.Customers.Update(temp)

        if (response.status === 200) {
            setCustomers(prevCustomers => {
                // Create a map of updated customers from response
                const updatedCustomersMap = new Map(response.data.data.map(customer => [customer._id, customer]));
        
                return prevCustomers.map(customer => 
                    updatedCustomersMap.has(customer._id) 
                        ? updatedCustomersMap.get(customer._id)  // Use updated customer from API
                        : customer // Keep existing customer if not updated
                );
            });
        
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }));
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: "Error updating customer(s)...", type: "error" }));
        }
        
        handleCloseUpdateModal(false) 
    };

    // API Request to Delete a user
    const handleDeleteSubmit = async () => {
        const response = await API.Customers.Delete(selectedRows)
        if (response.status === 200) {
            setCustomers(prevCustomers =>
                prevCustomers.filter(customer => !selectedRows.includes(customer._id))
            );
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: 'success' }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }

        setSelectedRows([]) 
        handleCloseUpdateModal()
    }

    const handleMoveToDash = async () => {
        console.log(user)

        const response = await API.Dashboard.SendCustomersToDash(localStorage.id, selectedRows)
        if (response.status === 200) {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }
        setSelectedRows([]) 
        handleCloseUpdateModal()
    }

    // Define buttons for ActionButtons component
    const actionButtons = [
        { title: "Create Customer", onClick: handleOpenCreateModal, icon: AddCircle, color: "primary" },
        { title: "Update Selected Record(s)", onClick: handleOpenUpdateModal, icon: Construction, color: "primary" },
    ];
    const updateCustomerFields = [
        { name: "owner", label: "Owner", type: 'autocomplete', options: users, getOptionLabel: (option) => option.label },
        { name: "organizationName", label: "Organization Name", type: "text" },
        { name: "address", label: "Address", type: "text" },
        { name: "city", label: "City", type: "text" },
        { name: "state", label: "State", type: "text" },
        { name: "province", label: "Province", type: "text" },
        { name: "countryCode", label: "Country", type: "text" },
        { name: "status", label: "Status", type: 'autocomplete',  options: ['New', 'Active', 'Inactive', 'Pending', 'Dormant', 'Churned']}
    ]

    useEffect(() => {
        async function getData() {
            try {
                const response = await API.Customers.FetchAll()
                const usersData = await API.Users.FetchAll()
                let formattedUsers = usersData.map(user => (
                    { label: `${user.firstName} ${user.lastName}`, id: user._id }                
                ))
                setUsers(formattedUsers)
                setCustomers(response)   
            } catch (error) {
                setFeedback(prevData => ({ ...prevData, open: true, message: 'Error: Failed To Load Customers...', type: "error" }))
            } finally {
                setLoading(false)
            }
        }
        getData()
    },[])

    return (
        <>
        {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        ) : (
            <FormProvider>
                <Feedback props={feedback} setFeedback={setFeedback} />
                <ActionButtons buttons={actionButtons} />
                <RecordModal open={openCreateModal} handleClose={handleCloseCreateModal} fields={CustomersConfig.NewCustomerFields} title="Create New Customer" onSubmit={handleCreateSubmit} />
                <RecordModal open={openUpdateModal} handleClose={handleCloseUpdateModal} fields={updateCustomerFields} title="Update Customer(s)" onSubmit={handleUpdateSubmit} onDelete={handleDeleteSubmit} isUpdate={true} allowMoveToDash={true} onMoveToDash={handleMoveToDash} />
                <DataTable rows={customers} columns={columns} handleSelectionChange={handleSelectionChange} />
            </FormProvider>    
        )}   
    </>     
    )
}

export default Content