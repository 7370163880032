import React from 'react';
import { Modal, Box, Typography, Button, useMediaQuery, Autocomplete, TextField, FormControl } from '@mui/material';
import { useFormContext } from '../../contexts/CreateRecordFormContext';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const RecordModal = ({ open, handleClose, fields, title, onSubmit, onDelete, isUpdate, allowMoveToDash, onMoveToDash }) => {
  const { formState, updateField, resetForm } = useFormContext();
  
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLaptop = useMediaQuery('(max-width:1280px)')

  const handleChange = (e) => {
    updateField(e.target.name, e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(formState); // Pass the form state when submitting
};

  // Call resetForm when closing the modal
  const handleModalClose = () => {
    resetForm();  // Reset the form state when the modal closes
    handleClose();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '85vw' : isLaptop ? '60vw' : '30vw',
    bgcolor: 'background.paper',
    border: '2px solid #5b6b79',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  }
  

  return (
    <Modal open={open} onClose={handleModalClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">{title}</Typography>
        <form onSubmit={handleFormSubmit}>
          {fields.map((field) => (
            <div key={field.name}>
              {field.type === 'autocomplete' ? (
                <Autocomplete
                // onInputChange={(event, value) => {
                //   if (field.handleSearch) {
                //     field.handleSearch(event, value);
                //   }
                // }}
                  sx={{marginTop: "1em", marginBottom: "1em"}}
                  options={field.options}
                  onChange={(event, newValue) => updateField(field.name, newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label={field.label} variant="outlined" />
                  )}
                />
              ) : field.type === 'date' ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormControl fullWidth>
                  <DatePicker
                      sx={{marginTop: "1em", marginBottom: "1em"}}
                      label={field.label}
                      value={formState[field.name] || ''}
                      onChange={(date) => updateField(field.name, date)}
                      renderInput={(params) => <TextField {...params} />}
                  />
                  </FormControl>
                </LocalizationProvider>                      
              ) : (
                <TextField
                  required={field?.required && true}
                  type={field.type}
                  name={field.name}
                  label={field.label}
                  value={formState[field.name] || ''}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            </div>
          ))}
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <Button type="submit" variant="contained" color="primary" sx={{ marginTop: "1em" }}>
              Submit
            </Button>
            {isUpdate && <Button variant="contained" color="error" sx={{marginTop: "1em"}} onClick={onDelete}>Delete</Button>}
            {allowMoveToDash && <Button variant="contained" color="warning" sx={{marginTop: "1em"}} onClick={onMoveToDash}>Add To Dashboard</Button>}
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default RecordModal;
