import { DataGridPro } from "@mui/x-data-grid-pro";
import { Delete } from "@mui/icons-material";
import { Card, CardContent, CardHeader, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { API } from "../../lib/api";
const CustomersTable = ({ customers, setCustomers, setFeedback, user }) => {

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleRemove = async (id) => {
        if (!user) {
            setFeedback({open: true, message: "No User Detected...", type: "error"})
            return
        } 
        const response = await API.Dashboard.RemoveCustomer(user,id)
        if (response.status === 200) {
            setCustomers(prev => prev.filter(opp => opp._id !== id)); // 👈 Update state
            setFeedback({open: true, message: "Removed Customer From Dashboard!", type: "success"})
        } else {
            setFeedback({open: true, message: "Error removing customer...", type: "error"})
        }
    }

    const ResponsiveColumns = [
        {
            field: "organizationName",
            headerName: "Customer",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: ({row: { _id, organizationName } }) => <a href={`/customers/${_id}`}>{organizationName}</a>
          },
            // Conditionally show/hide columns
            ...(isMobile ? [] : [
                {
                    field: "owner",
                    headerName: "Owner",
                    flex: 1,
                    renderCell: ({ row: { owner } }) => owner ? `${owner.firstName} ${owner.lastName}` : 'Unassigned',
                    valueGetter: (params) => `${params.firstName} ${params.lastName}`
                },
                {
                    field: "city",
                    headerName: "City",
                    flex: 1
                },
                {
                    field: "state",
                    headerName: "State/Province",
                    flex: 1,
                    renderCell: ({ row: {state, province }}) => state ? state : province
                },
                {
                    field: "address",
                    headerName: "Address",
                    flex: 1,
                },
                {
                    field: 'countryCode',
                    headerName: "Country",
                    flex: 1
                },
            ]),
            {
                field: "status",
                headerName: "Status",
                flex: 1,
                renderCell: ({ row: { status } }) => {
                    return (
                        <label className={`badge  ${status === 'New' ? 'bg-light-primary' : status === 'Active' ? 'bg-light-success' : status === 'Churned' ? 'bg-light-danger' : status === 'Dormant' ? 'bg-light-secondary' : 'bg-light-warning'}`} style={{minHeight: '75%', minWidth: '75%', padding: "0", lineHeight: '3', fontSize: '14px'}}>{status}</label>
                    );
                }
            },
            {
                field: "_id",
                headerName: "",
                flex: 1,
                renderCell: ({ row: { _id }}) => {
                    const id = _id 
                    return (
                        <Tooltip title="Remove From Dashboard">
                            <IconButton onClick={() => handleRemove(id)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )
                    
                },
            }
    ];

    return (
        <div className="col-xl-12 col-md-12" style={{marginTop: "1em", marginBottom: "1em"}}>
            <Card>
                <CardHeader title="Customers" sx={{color: "#4680ff", backgroundColor: "#edf2ff"}} />
                <CardContent sx={{color: "#4680ff", backgroundColor: "#edf2ff"}}>
                    <DataGridPro
                    sx={{backgroundColor: "white"}}
                        checkboxSelection
                        rows={customers ? customers : []}
                        columns={ResponsiveColumns}
                        getRowId={(row) => row._id}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                    />
                </CardContent>
            </Card>
        </div>
    )
}

export default CustomersTable