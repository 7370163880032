import { createContext, useContext, useState, useEffect } from 'react';
import { API } from '../lib/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Check if user is logged in via cookie
  useEffect(() => {
    localStorage.removeItem('token');
    const checkAuth = async () => {
      try {
        const response = await API.Authentication.ValidateUser()
        if (response.status === 200) {
          setUser(response.data.user); // comes from req.user in backend
        } else {
          setUser(null)
        }
      } catch (error) {
        setUser(null); // not logged in
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData)); // this is fine
    localStorage.setItem('id', userData.id); // optional depending on how you use it
    localStorage.setItem("email", userData.email)
    localStorage.setItem("department", userData.department)
    localStorage.setItem("role", userData.role)
  };

  const logout = async () => {
    const response = await API.Authentication.Logout()
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);