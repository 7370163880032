import { useEffect, useState } from "react";
import { API } from "../../lib/api";
import Feedback from "../../components/Feedback/Feedback";
import { Box, CircularProgress } from "@mui/material";
import { FormProvider } from "../../contexts/CreateRecordFormContext";
import ResponsiveColumns from "./ResponsiveColumns";
import DataTable from "../../components/DataTable/DataTable";

const Content = () => {
    const [contacts, setContacts] = useState([]) 
    const [loading, setLoading] = useState(true);
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""}) 
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = ResponsiveColumns()
    
    useEffect(() => {
        async function getData() {
            try {
                const response = await API.Customers.FetchAll()
                const allContacts = response.flatMap(customer =>
                    customer.contacts.map(contact => ({
                      ...contact,
                      organizationName: customer.organizationName
                    }))
                );
                console.log(allContacts)
                setContacts(allContacts)   
            } catch (error) {
                setFeedback(prevData => ({ ...prevData, open: true, message: 'Error: Failed To Load Customers...', type: "error" }))
            } finally {
                setLoading(false)
            }
        }
        getData()
    },[])


    return (
        <>
        {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        ) : (
            <FormProvider>
                <Feedback props={feedback} setFeedback={setFeedback} />
                {/* <ActionButtons buttons={actionButtons} /> */}
                {/* <RecordModal open={openCreateModal} handleClose={handleCloseCreateModal} fields={CustomersConfig.NewCustomerFields} title="Create New Customer" onSubmit={handleCreateSubmit} /> */}
                {/* <RecordModal open={openUpdateModal} handleClose={handleCloseUpdateModal} fields={updateCustomerFields} title="Update Customer(s)" onSubmit={handleUpdateSubmit} onDelete={handleDeleteSubmit} isUpdate={true} allowMoveToDash={true} onMoveToDash={handleMoveToDash} /> */}
                <DataTable rows={contacts} columns={columns} />
            </FormProvider>    
        )}   
    </>     
    )
}

export default Content